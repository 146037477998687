<template>
      <div :class="'h5-page ' + (miniapp ? '' : 'with-nav-bar')">

      <!-- <t-navbar :title="title" :fixed="true" v-if="is_home"></t-navbar> -->

      <t-navbar :title="title" :fixed="true" v-if="!miniapp"></t-navbar>
      <G-Loading v-if="pageStore.loading || modeling" />
      
      <div class="home-form" v-if="false">
          <div class="align-items-start h-50">
              <div class="col">
                  
                  <div class="magic">
                    
                      <textarea v-model="prompt" enterkeyhint="send"></textarea> 
                      <button class="btn btn-primary send-button" @click="magic" :disabled="modeling || prompt== ''" >
                        <icon-font name="send" />
                      </button>
                  </div>

                  <div class="tag-list">
                    <t-tag variant="outline" 
                    shape="round"
                    v-for="(word, index) in show_prompt" 
                    :key="'prompt-' + index" 
                    @click="inputPrompt(word)">{{ word }}</t-tag>
                  </div>
                  <div class="">
                      
                  </div>
              </div>
          </div>

      </div>

      <t-dialog
    v-model:visible="isShowDialog"
    :content="message"
    confirm-btn="知道了"
    :close-on-overlay-click="true"
  >
    </t-dialog>

    <t-dialog
      v-model:visible="upgradeDialog"
      close-on-overlay-click
      :content="message"
      cancel-btn="取消"
      :confirm-btn="{ content: '马上升级', danger: 'success' }"
      @confirm="onConfirm"
      @cancel="onCancel"
      @close="onClose"
    >
    </t-dialog>


     <t-tabs v-if="isLogin" :defaultValue="type" @change="onChange">
        <t-tab-panel value="mine" label="我的" v-if="isLogin"/>
        <t-tab-panel value="collect" label="收藏" />
        <t-tab-panel value="like" label="赞过" />
        <t-tab-panel value="view" label="看过" />
      </t-tabs>
  
      <div id="app" class="page-bg">

        <div class="failure" v-if="connect_failure || pageStore.error != null">
          <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
        </div>
        
        <!-- <div class="search-bar" v-if="!connect_failure">
          <t-search v-model="search_keyword" :clearable="true" shape="round" placeholder="请输入关键字" @change="onChange"></t-search>
          
        </div> -->
        <!-- <button class="btn btn-primary" @click="search">搜索</button> -->

        <!-- lock-on -->
        
          <div class="infinite-scroll-list page-list" v-if="isLogin">
            <EmptyTips :word="empty_tips[type]" v-if="pageStore.isEmpty(type)"/>

            <div class="page infinite-scroll-item" v-for="page in pageStore[type+'Items']" :key="page.page_uuid" @click="showPage(page.page_uuid)">
                <div class="name">
                    <span>{{page.title}}</span>
                </div>
                <div class="row">
                  <div class="icon-btn"> 
                    <img src="/images/like.png" width="18" height="20" style="margin-top:-2px;"> <span>{{ page.like_count }}</span> 
                  </div>
                  <div class="icon-btn"> 
                    <img src="/images/collect.png" width="20" style="margin-top:-2px;">  <span>{{ page.collect_count }}</span>   </div>
                  <div class="icon-btn"> <icon-font name="share-1" /><span>{{ page.share_count }}</span>  </div>
                </div>
            </div>
          </div>
      
          
          <YiInfiniteLoading
            :loading="isLoading"
            :finished="pageStore.finished[type]"
            offset="100%"
            :hideLoading="true"
            @loadMore="loadMore" 
          >
          <t-loading theme="dots" size="25px" text="加载下一页" style="left:35%;" v-if="pageStore.loadingMore" />

          </YiInfiniteLoading>
            
          <div class="clear" >
            <!-- <button @click="clear" >clear</button>
            <button v-if="!isLoading" @click="loadMore" >load more</button> -->
          </div>
            
          
      </div>
      <div class="bottom-bar"> </div>
      <GuTabBar selectedIndex="1"></GuTabBar>
    </div>
    
   </template>
   
   <script>
     /* eslint-disable */
     
   import api from '../common/api'; 
   import { Empty, Toast } from 'tdesign-mobile-vue';
   import 'bootstrap/dist/css/bootstrap.min.css';
   import { mapGetters, mapActions } from 'vuex';
   import { IconFont } from 'tdesign-icons-vue-next';
   import YiInfiniteLoading from 'yi-infinite-loading-vue3'
   import GuTabBar from '../components/GuTabBar.vue';
   import EmptyTips from '../components/EmptyTips.vue';
   import LineCard from '../components/LineCard.vue'
   // const newIconfontUrl = 'https://tdesign.gtimg.com/icon/default-demo/index.css';
   import { isWechatEnv, isMiniApp } from '@/common/tools'
   import { usePageStore } from '@/stores/PageStore';

   export default {
     name: 'Home',
     components: {
       IconFont,
       GuTabBar,
       YiInfiniteLoading,
       EmptyTips,
       LineCard
     },
     computed: {
      ...mapGetters(['account', 'isLogin'])
     },
     data: () =>{
          return {
              pageStore: usePageStore(),
              title: '我的',
              type: 'mine', // 页面类型 首页列表，我的收藏，最近查看
              is_loading: false,
              connect_failure: false,
              isLoading: false,
              finished: false,
              is_home: true, // 默认首页
              modeling: false,
              miniapp: false,
              prompt: '',
              isShowDialog: false,
              upgradeDialog: false,
              message: '',
              empty_tips: {
                mine: '马上创建一个新画板吧',
                collect: '你还没有收藏画板哦',
                like: '你还没有赞过别人的画板呢',
                view: '你还没有看过别人的画板'
              },
              show_prompt: [],
              tip_prompt: [
                '深圳、广州、杭州A股上市公司数量对比',
                '市值超过1000亿的A股年涨幅前10强',
                'A股市值100亿以上52周新低，按市值前10',
                '特斯拉、比亚迪股价、市值，年涨幅对比',
                '杭州A股公司前10名',
                '今日A股涨跌数量分布',
                '美股最近20天跌幅最大的10只股票',
                '小鹏汽车、理想汽车市值，年涨幅对比',
              ]
          }
     },
     created() {
      

      if (!this.isLogin) {
          this.$router.push('/login?from=' + this.$route.path);
          return
      }

      this.miniapp = isMiniApp()  // 判断是否在小程序内
      this.show_prompt = this.getRandomPrompts(this.tip_prompt) // 生成随机提示语

      this.type = this.pageStore.minePageType;
      this.pageStore.fetchItems(this.type);

      this.$nextTick(() => {
        var list = document.getElementsByClassName('page-list')[0]
        list.scrollTop = this.pageStore.restoreScrollPosition(this.type)
      })

      this.$UMTrackPV({page: 'mine', type: 'mine'});
      // 检查是否已登录
      // if (this.isLogin) {

      // } else {
        // const code = this.$route.query.code
        // if (code) {
        //   // 小程序自动登录
        //   this.loginByWechat(code)
        // }
      // }
      
     },
     beforeRouteLeave(to, from, next) {
        var list = document.getElementsByClassName('page-list')[0]
        this.pageStore.saveScrollPosition(this.type, list.scrollTop)
        this.pageStore.setMinePageType(this.type)
        next();
    },
     methods: {
      ...mapActions(['loginSuccess', 'logoutSuccess']),
      getRandomPrompts(data) {
        // 使用slice()方法复制数组，避免修改原始数组
          let shuffledArray = data.slice();

        // Fisher-Yates洗牌算法
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray.slice(0, 3);
      },
      inputPrompt(word) {
        this.prompt = word
      }, 
      getWehatCode() {
        
      },
     
      // 小程序自动登录
      loginByWechat(code) {
        var params = { code: code }
        api.post('/wechat_login', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            Toast.success('小程序免密登录成功')
            // 设置cookies，有效期7天
            document.cookie = `jwt=${result.token};path=/;max-age=${60*60*24*7};`;
            this.loginSuccess(result.account)
          }
        })
      },

      loadMore() {
        this.pageStore.loadMoreItems(this.type)
      },
      onChange(event, label) 
      {
        // 处理事件
        if (label == '我的') { 
          this.type = 'mine'
        } else if (label == '收藏') {
          this.type = 'collect'
        } else if (label == '赞过') {
          this.type = 'like'
        } else {
          this.type = 'view'
        }
        this.pageStore.fetchItems(this.type);
      },
      showPage(page_id) {
        this.$router.push('/pages/' + page_id);
      },
      like(page_id) {
        
        api.post('/pages/' + page_id + '/like')
         .then(response => {
           const result = response.data.status;
           if (result == 'success') {
             Toast('点赞成功')
           } else {
             Toast('点赞失败')
           }
         })
  
      },
      comment(page_id) {
        Toast('评论')
      },
      checkAndFit() {
        if (("standalone" in window.navigator) && window.navigator.standalone) {
          Toast.success('当前为独立打开模式')
        } else {
          Toast.success('当前为非独立打开模式')
        }
      },
      dialog(content) {
        this.message = content
        this.isShowDialog = true
      },
      checkPrompt() {
        if (this.prompt == '') {
          this.dialog('请输入查询提示语')
          return false
        }

        if (this.prompt.length <= 4) {
          this.dialog('请输入不少于5个字的查询提示语')
          return false
        }

        if (this.prompt.length >= 150) {
          this.dialog('提示语不能超过150个字')
          return false
        }
        return true
      },
      // 生成图表
      // 创建Page，创建Card
      // 文字 => Page title，card title
      // 若没有权限，则提醒
      magic(){
        if (!this.isLogin) {
          this.$router.push('/login?from=' + this.$route.path);
          return
        }

        if (!this.checkPrompt()) {
          return;
        }

        this.modeling = true
        var url = '/magic?from=home'
        api.post(url, {
            prompt: this.prompt,
            }).then(response => {
                const result = response.data
                if (result.status == 'success') {
                  Toast.success('生成成功')
                  this.showPage(result.page_id)
                } else {
                  this.message = result.message
                  if (result.type =='upgrade') {
                    this.upgradeDialog = true
                  } else {
                    this.isShowDialog = true
                  }
                  // Toast(result.message)
                }
            }).finally(() => {
              this.modeling = false
            })
      },
      // 确认升级会员，跳转到升级页面
      onConfirm() {
        this.$router.push('/vip');
      }
     }
   };
   </script>
   <style scoped>

   .tag-list {
      text-align: left;
      margin-top: 5px;
   }
   .t-tag {
    margin-bottom:5px;
   }
   .magic {
      height: 60px;
      display: flex;
      position: relative;
   }

   .magic-word {
    border: #ECECEC 1px solid ;
    padding: 2px;
    border-radius: 5px;
   }

   .send-button {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    /* color: var(--msh-chat-send-button-color);
    background-color: var(--msh-chat-send-button-bg); */
    height: 36px;
    width: 40px;
   }

   .home-form {
      width: 100%;
      height: 160px;
      padding: 10px;
      text-align: center;
   }

   .home-form  textarea{
      flex: 1; 
      float:left; 
      height: 55px;
      margin-right: 5px;
      padding: 5px;
      padding-right: 50px;
      border-radius: 10px;
   }

   .home-form textarea:focus {
    border-color: #0d6efd; /* 设置聚焦时的边框颜色 */
    outline: none; /* 可选：移除默认的聚焦轮廓线 */
  }
  
   .search-bar {
  
      padding: 10px 5px;
      width: 100%;
      height: 60px;
   }
  
   .page .name {
      font-size: 16px;
      width:100%;
      clear:both;
   }
   .page .icon-btn {
     width: 100px;
     margin-top: 10px;
     margin-right:10px;
     float:left;
     height: 28px;
     
   }
   .page .icon-btn span {
      padding-left: 5px;
   }
  
   .failure {
    height: 300px;
    padding-top: 80px;
   }
  
  .page-list {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100vh - 96px - 56px - env(safe-area-inset-top));
    overflow-y: auto;
  }

  /* 定义一个关键帧动画 */
  @keyframes scale-up {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.2);
    }
  }

  /* 应用动画到元素 */
  .scale-up {
    animation: scale-up 0.3s ease-in-out forwards; /* 动画持续时间、缓动函数和动画结束后的状态 */
  }

   </style>