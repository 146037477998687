<template>
  <!-- <t-footer text="Copyright © 2024 Gututu.net All Rights Reserved." /> -->

<t-tab-bar v-model="value" theme="tag" :split="false" @change="change">
  <t-tab-bar-item v-for="item in list" :key="item.value" :value="item.value">
    {{ item.label }}
    <template #icon>
      <t-icon :name="item.icon" />
    </template>
  </t-tab-bar-item>
</t-tab-bar>

</template>
  <script>
  /* eslint-disable */
  export default {
    name: 'GuTabBar',
    props: {
      selectedIndex: Number,
    },
    computed: {

    },
    data() {
      return {
        value: 'explore',
        list: [
          { value: 'explore', label: '首页', icon: 'home' },
          { value: 'mine', label: '我的', icon: 'user' },
          { value: 'more', label: '更多', icon: 'app' },
        ],
      }
    },
    created() {
      this.value = ['explore', 'mine', 'more'][this.selectedIndex]
    },
    methods: {
      change(changeValue) 
      {
        if (changeValue == 'mine') {
          this.$router.push('/mine');
        } else if (changeValue == 'explore') {
          this.$router.push('/');
        } else {
          this.$router.push('/more');
        }
      },
    }
  };
  </script>
