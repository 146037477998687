<template>
   
    <div class="h5-page">
      <t-navbar title="设置" :fixed="true" left-arrow @left-click="goBack">
      </t-navbar>
      <div class="bottom-bar"></div>
      <t-cell-group bordered>
        <t-cell v-if="isLogin" :left-icon="modify_icon" title="修改资料" arrow hover @click="gotoPage('profile/modify')"/>
          <t-cell :left-icon="agreement_icon" title="服务条款" arrow hover @click="gotoPage('agreement')"/>
          <t-cell :left-icon="internet" title="隐私协议" arrow hover @click="gotoPage('privacy')"/>
          <t-cell :left-icon="data_icon" title="个人信息收集清单" arrow hover @click="gotoPage('collect_data')"/>
          <!-- <t-cell :left-icon="share_icon" title="第三方共享信息" arrow hover @click="gotoPage('third_share')"/> -->
          <t-cell v-if="is_login" :left-icon="logout_icon" title="退出" arrow hover @click="logout()"/>
          <t-cell v-else :left-icon="lock" title="登录" arrow hover @click="gotoPage('login')"/>
          
        </t-cell-group>
        <div class="bottom-bar"></div>
        <t-footer :text="footer_text"/>
      </div>
   </template>
   
   <script>
     /* eslint-disable */
     import { h } from 'vue';
     import { LockOnIcon, ServiceIcon, InternetIcon, Edit1Icon, DataIcon, ShareIcon, LogoutIcon, AssignmentIcon } from 'tdesign-icons-vue-next';
     import { Toast } from 'tdesign-mobile-vue';
     import { mapGetters } from 'vuex';
     import { usePageStore } from '@/stores/PageStore';
    //  import { useAuth } from '../common/useAuth';
  
   export default {
     name: 'Setting',
     components: {
 
     },
     computed: {
       ...mapGetters(['isLogin'])
     },
     data() {
       return {
         lock : h(LockOnIcon),
         modify_icon: h(Edit1Icon),
         agreement_icon: h(AssignmentIcon),
         service: h(ServiceIcon),
         internet: h(InternetIcon),
         data_icon: h(DataIcon),
         share_icon: h(ShareIcon),
         logout_icon: h(LogoutIcon),
         is_login: false,
         footer_text: '',
         version: '1.0.0'
       };
     },
     created() {
        this.checkLoginState()
        this.footer_text = 'V' + this.version +' Copyright © 2024 Gututu.ai All Rights Reserved.'
     },
     methods: {
        goBack() {
            this.$router.back();
      } ,
        checkLoginState() {
          const token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
          if (token != undefined) {
            this.is_login = true;
          } else {
            this.is_login = false;
          }
        },
        gotoPage(page) {
          this.$UMTrackPV({page: 'Setting', action: page});
          this.$router.push('/' + page);
        },
        logout() {
          // 删除cookie的token
          document.cookie = 'jwt=; path=/; max-age=0';
          var token = document.cookie.split('; ').find(row => row.startsWith('jwt='))?.split('=')[1];
          this.checkLoginState()

          // 清空缓存数据
          const pageStore = usePageStore();
          pageStore.clearPageData();

          Toast('退出成功')
        }
     }
   };
   </script>
  
  <style scoped>
  .avatar {
      width: 120px;
      height: 120px;
  }
  </style>