<template>
    <div>  
      <div id="share" class="card-list page-bg">
          <Banner />

          <CardById
            :card_id="card_id" 
            hide_btn="true" />  
    
        <div style="font-size: 13px; color: #888;padding-left:15px;padding-bottom: 10px;">数据截止到 {{ dataUpdateDate }}</div>
      </div>
    </div>
  </template>
  
  <script>
    /* eslint-disable */
  import CardById from '@/components/CardById.vue';
  import { IconFont } from 'tdesign-icons-vue-next';
  import dayjs from 'dayjs';
  import Banner from '@/components/Banner.vue'

  const getTradeDate = () => {
    const now = dayjs();
    const dayOfWeek = now.day();
    var date = now;
  
    if (dayOfWeek == 6) {
      date = now.subtract(1, 'day');
    } else if (dayOfWeek == 0) {
      date = now.subtract(2, 'day')
    } else if (dayOfWeek == 1 && now.hour() + now.minute() / 60 < 9.5) {
      date = now.subtract(3, 'day')
    } else if (dayOfWeek > 1 && dayOfWeek < 6 && now.hour() + now.minute() / 60 < 9.5) {
      // 每天早上9:30前返回昨天的日期
      date = now.subtract(1, 'day');
    }
    return date.format('YYYY-MM-DD')
  }
  
  
  export default {
    name: 'CardShare',
    components: {
      CardById,
      Banner
    },
    computed: {
     },
    data() {
      return {
        card_id: 70
      }
    },
    created() {
      this.card_id = this.$route.params.card_id;
      this.dataUpdateDate = getTradeDate() 
    },
    methods: {

    }
  };
  </script>
  
  <style scoped>
  .card-form {
    padding: 10px 25px;
  }
  .page-bg {
      background: #EDEDED;
  }
   
  </style>