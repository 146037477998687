<template>
  <div class="h5-page">
    <!-- <t-navbar :title="title" :fixed="true" v-if="!miniapp"></t-navbar> -->

    <t-navbar :title="title" :fixed="true" left-arrow @left-click="goBack"  v-if="!is_home"></t-navbar>

    <!-- <t-overlay :visible="loading" /> -->

    <G-Loading v-if="pageStore.loading" />

    <div class="home-logo">
        <img src="https://m.gututu.net/images/icon.png" alt="logo" width="80px" style="border-radius: 15px"/>
        <div class="text">
                      <div class="slogan">股图图，AI金融应用</div>
                      <div class="desc">
                        <!-- 输入文字，大模型生成📈图表<br> -->
                        A港美股💰数据，准确性🎓更可靠<br></div>
         </div>
      </div>
    
      <t-fab :icon="addIcon" text="添加画板" @click="addPrompt" 
    style="right:16px; bottom: calc(82px + env(safe-area-inset-bottom)); "/>

      <!-- <t-sticky> -->
        <t-tabs :default-value="type"  @change="onChange" v-if="is_home">
        <!-- <t-tab-panel value="0" label="我的" v-if="isLogin"/> -->
          <t-tab-panel value="hot" label="热门" />
          <t-tab-panel value="new" label="最新" />
        </t-tabs>
      <!-- </t-sticky> -->


      <div id="app" class="container page-bg">
        <div class="failure" v-if="connect_failure || pageStore.error != null">
          <t-result theme="warning" title="网络异常" description="请检查手机网络信号是否正常" class="space" />
        </div>
        
        <div class="search-bar" v-if="!connect_failure && pageStore.error == null">
          <t-search v-model="search_keyword" :clearable="true" 
          shape="round" placeholder="请输入画板关键字" 
          @change="onSearchChange" style="width:100%;"  
          enterkeyhint="search"
          @submit="search"
              ></t-search>
          <!-- <button class="btn btn-primary" style="width:60px;float:right;display: flex;" @click="search">搜索</button> -->
        </div>
        <!-- <button class="btn btn-primary" @click="search">搜索</button> -->
      
        <div class="infinite-scroll-list page-list" ref="listContainer">
          <div class="empty-tip" v-if="pageStore.isEmpty(type)">
            <t-result theme="default" title="没有画板" class="space" />
          </div>

          <div class="page infinite-scroll-item" v-for="page in (onSearch ? pageStore.searchResult : pageStore[type+'Items'])" :key="page.page_uuid" @click="showPage(page.page_uuid)">
              <div class="title">
                  <span>{{page.title}}</span>
              </div>
              <div class="row">
                <div class="name" v-if="page.user"> 
                  <img :src="page.user.avatar" width="18" height="18" style="margin-top:4px;"> 
                  <div class="page-name-text">{{ page.user.name }} 
                  <img src="https://m.gututu.net/images/vip.png" width="16" height="16" v-if="page.user.vip_level > 0">
                  </div>
                </div>
                <div class="icon-btn" @click.stop="like(page.page_uuid)">
                  <img src="/images/like.png" width="18" height="20" style="margin-top:4px;"> <span style="margin-top:4px;color:#666;font-size:14px;">{{ page.like_count }}</span> 
                </div>
              </div>
          </div>
        </div>

        <YiInfiniteLoading
          :loading="isLoading"
          :finished="finished"
          offset="100%"
          :hideLoading="true"
          @loadMore="loadMore"
        >
  
          <t-loading theme="dots" size="25px" text="加载下一页" style="left:35%;" v-if="pageStore.loadingMore" />

          <!-- <span class="tips" v-if="isLoading">正在加载</span> -->
          <!-- <span class="tips" v-if="finished && pages.length > 0">没有更多</span> -->
        </YiInfiniteLoading>
          
        <div class="clear" >
          <!-- <button @click="clear" >clear</button>
          <button v-if="!isLoading" @click="loadMore" >load more</button> -->
        </div>
          
        
    </div>
    <div class="bottom-bar"> </div>
    
    
    <div class="bottom-popup">
      <t-popup v-model="addCard" placement="bottom" style="padding: 15px">
        <div v-if="modeling" class="popup-desc">
           <div style="float:left; margin-right:10px;">大模型正在生成图表</div> <t-loading class="bottom-loading" theme="dots" size="20px" style="float:left;"/>
        </div>
        <div v-else class="popup-desc">
           <div style="float:left; margin-right:10px;">请输入卡片描述</div> 
        </div>
        <div style="display: flex;">
            <textarea v-model="prompt" style="height:120px; flex: 1; float:left; margin-right: 10px;"></textarea>
            <button class="btn btn-primary" style="height:120px; width: 50px;float:right; " @click="magic()"  :disabled="modeling">生成
            </button>
        </div>
        <div style="height:26px;"> </div>
      </t-popup>
    </div>

    <t-dialog
    v-model:visible="isShowDialog"
    :content="message"
    confirm-btn="知道了"
    :close-on-overlay-click="true"
  >
    </t-dialog>

    <t-dialog
      v-model:visible="upgradeDialog"
      close-on-overlay-click
      :content="message"
      cancel-btn="取消"
      :confirm-btn="{ content: '马上升级', danger: 'success' }"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
    </t-dialog>

    <GuTabBar selectedIndex="0" v-if="is_home"></GuTabBar>
  </div>
 </template>
 
 <script>
   /* eslint-disable */
   
 import api from '../common/api'; 
 import { Toast } from 'tdesign-mobile-vue';
 import 'bootstrap/dist/css/bootstrap.min.css';
 import { mapGetters } from 'vuex';
 import { IconFont } from 'tdesign-icons-vue-next';
 import YiInfiniteLoading from 'yi-infinite-loading-vue3'
 import GuTabBar from '../components/GuTabBar.vue';
 import { isWechatEnv, isMiniApp } from '../common/tools'
 import { getOpenId } from '@/common/wechat'
 import { usePageStore } from '@/stores/PageStore';
 import { useShareStore } from '@/stores/ShareStore';

 export default {
   name: 'PageList',
   components: {
     IconFont,
     GuTabBar,
     YiInfiniteLoading
   },
   computed: {
    ...mapGetters(['account', 'isLogin', 'isVip']),
   },
   data: () =>{
        return {
            title: '股图图',
            type: 'hot', // 页面类型 首页列表，我的收藏，最近查看
            onSearch: false,
            pages: [],
            loading: false,
            connect_failure: false,
            isLoading: false,
            finished: false,
            page_number: 1,
            is_search: false,
            search_keyword: '',
            is_home: true, // 默认首页,
            miniapp: false,
            modeling: false,
            prompt: '',
            isShowDialog: false,
            upgradeDialog: false,
            message: '',
            addCard: false,
            pageStore: usePageStore(),
            shareStore: useShareStore(),
        }
   },
   created() {
    

      this.miniapp = isMiniApp()
      
      if (this.miniapp) {
        // 小程序分享出去的卡片，点击来自动跳转
        this.miniAppRedirect()
    
        // 根据code获得用户微信小程序的openid
        this.getMiniAppOpenID()
      } 

      if (isWechatEnv()) {
        getOpenId().then(res => {
          // console.log(res, '公众号openid')
        })
      }

      this.type = this.pageStore.homePageType
      // 加载数据

      this.pageStore.fetchItems(this.type).then(data => {
        setTimeout(() => {
          var list = document.getElementsByClassName('h5-page')[0]
          const position = this.pageStore.restoreScrollPosition(this.type)
          if (position > 0) {
            list.scrollTop = position
          }
        }, 150)
      })

      this.$UMTrackPV({page: 'Home', type: 'hot'});      
   },
   beforeRouteLeave(to, from, next) {
      var list = document.getElementsByClassName('h5-page')[0]
      this.pageStore.saveScrollPosition(this.type, list.scrollTop)
      this.pageStore.setHomePageType(this.type)
      next();
  },
   methods: {
    // 确认升级会员，跳转到升级页面
    onConfirm() {
        this.$router.push('/vip');
    },
    getMiniAppOpenID() {
      if (!localStorage.getItem("miniapp_openid")) {
        const code = this.$route.query.code

        if (!code) {
          return
        }
        var params = { code: code }
        api.post('/get_miniapp_openid', params).then(response => {
          const result = response.data
          if (result.status == 'success') {
            localStorage.setItem("miniapp_openid", result.openid ? result.openid : ""); 
          }
        })
      } else {
        var openid = localStorage.getItem("miniapp_openid");
        console.log('小程序当前用户openid: ' + openid)
      }
    },
    miniAppRedirect() {
      // 从详情页返回后，就不跳转
      if (this.shareStore.isView)
        return;

      // 小程序分享出去的卡片，点击来自动跳转
      if (this.$route.query.page_id)
      {
        this.$router.push('/pages/' + this.$route.query.page_id + '?from=share');
      }
    },
    goBack() {
      this.$router.back();
    },
    loadMore() {
      if (this.onSearch) {
        this.pageStore.loadMoreSearchResult(this.search_keyword)
      } else {
        this.pageStore.loadMoreItems(this.type)
      }
    },
    search() {
      if (this.search_keyword == '') {
        // 重置
        this.onSearch = false
        this.pageStore.fetchItems(this.type)
      } else {
        this.pageStore.search(this.search_keyword).then(data => {
          this.onSearch = true
        })
        this.$UMRecord('search', {keyword: this.search_keyword});
      }
    },
    onSearchChange(event) {

    },
    onChange(event, label) 
    {
      this.onSearch = false
      this.search_keyword = ''

      if (label == '我的') { 

      } else if (label == '热门') {
        this.$UMTrackPV({page: 'Home', type: 'hot'});
        this.type = 'hot'
      } else if (label == '最新') {
        this.$UMTrackPV({page: 'Home', type: 'new'});
        this.type = 'new'
      }
      this.pageStore.fetchItems(this.type)
    },
    showPage(page_id) {
      this.$router.push('/pages/' + page_id);
    },
    like(page_id) {
      api.post('/pages/' + page_id + '/like')
       .then(response => {
         const result = response.data.status;
         if (result == 'success') {
           Toast('点赞成功')
         } else {
           Toast('点赞失败')
         }
       })
    },
    comment(page_id) {
      Toast('评论')
    },
    share(page_id) {
      Toast('分享')
    },
    addPrompt() {
      this.$UMRecord('HomeAddPrompt', {isLogin: this.isLogin});

      if (!this.isLogin) {
          this.$router.push('/login?from=' + this.$route.path);
          return
      }

      if (!this.isVip) {
        this.message = '请升级到会员，开通画板权限，用AI提升10x投资分析效率'
        this.upgradeDialog = true
        return;
      }

      this.prompt = ''
      this.addCard = true
    },
    magic() {
        if (!this.checkPrompt()) {
          return;
        }

        this.modeling = true
        var url = '/magic?from=home'
        api.post(url, {
            prompt: this.prompt,
            }).then(response => {
                const result = response.data
                if (result.status == 'success') {
                  Toast.success('生成成功')
                  this.showPage(result.page_uuid)
                  this.pageStore.resetMineItems()
                } else {
                  this.message = result.message
                  if (result.type =='upgrade') {
                    this.upgradeDialog = true
                  } else {
                    this.isShowDialog = true
                  }
                  // Toast(result.message)
                }
            }).finally(() => {
              this.modeling = false
            })
    },
    checkPrompt() {
        if (this.prompt == '') {
          this.dialog('请输入查询提示语')
          return false
        }

        if (this.prompt.length <= 4) {
          this.dialog('请输入不少于5个字的查询提示语')
          return false
        }

        if (this.prompt.length >= 150) {
          this.dialog('提示语不能超过150个字')
          return false
        }
        return true
    },
    dialog(content) {
        this.message = content
        this.isShowDialog = true
    },
   }
 };
 </script>
 <style scoped>

 
.home-logo {
      margin-top: 20px;
      width: 100%;
      height: 170px;
      text-align: center;
   }

   .text {
      width: 380px;
      height: 80px;
      text-align: center;
      padding: 20px;
      /* padding-left: 80px; */
      margin: 0 auto;
      /* display: flex; */
   }

   .text .slogan {
      font-weight: bold;
      color: #222;
      font-size: 17px;
      margin-bottom: 5px;
   }
   .text .desc {
      color: #777;
   }

 .search-bar {
    display: flex;
    padding: 10px 5px;
    width: 100%;
    height: 60px;
 }

 .page .title {
    font-size: 16px;
    width:100%;
    clear:both;
 }

 .page .name {
   display: flex;
   width: 170px;
   margin-top: 15px;
   margin-right:10px;
   float:left;
   height: 30px;
 }

 .page-name-text {
  margin-left:6px;
  margin-top:5px;
  width: 140px;
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏超出元素部分的文本 */
  text-overflow: ellipsis;
  font-size: 13px;
  color: #666;
 }

 .page .icon-btn {
   display: flex;
   width: 80px;
   margin-top: 15px;
   margin-right:10px;
   float:left;
   height: 30px;
 }
 .page .icon-btn span {
    padding-left: 8px;
 }

 .failure {
  height: 300px;
  padding-top: 80px;
 }

 .empty-tip {
    background: #FFF;
    padding: 100px 0px;
 }
 .tips {
  text-align: center;
 }

 .bottom-popup {
    padding-bottom: constant(safe-area-inset-bottom); 
    padding-bottom: env(safe-area-inset-bottom); 
  }

  .popup-desc {
    clear:both; width: 100%;display: flex; padding-bottom: 8px;
  }
 </style>