// HotPageStore.js
import api from '@/common/api'; 
import { defineStore } from 'pinia';

export const usePageStore = defineStore('list', {
    state: () => ({
      hotItems: [],
      newItems: [],
      mineItems: [],
      likeItems: [],
      collectItems: [],
      viewItems: [],
      searchResult: [],
      minePageType: 'mine',
      homePageType: 'hot',
      currentType: 'hot',
      currentPage: {
        hot: 1,
        new: 1,
        mine: 1,
        like: 1,
        collect: 1,
        view: 1,
        search: 1
      },
      isLoaded: {
        hot: false,
        new: false,
        mine: false,
        like: false,
        collect: false,
        view: false,
      },
      finished: {
        hot: false,
        new: false,
        mine: false,
        like: false,
        collect: false,
        view: false,
        search: false
      },
      itemsPerPage: 20,
      loading: false,
      loadingMore: false,
      error: null,
      scrollPosition: {
        hot: 0,
        new: 0,
        mine: 0,
        like: 0,
        collect: 0,
        view: 0,
      }
    }),
    
    actions: {
        isEmpty(type) {
            if (type == 'search') {
                return this.searchResult.length == 0 && this.finished[type] && !this.loading
            } else {
                return this[type +'Items'].length == 0 && this.finished[type] && !this.loading
            }
        },
        getLoadUrl(type) {
            var url = ''
            switch (type) {
              case 'collect':
                url =  '/pages/mine/collect'
                break;
              case 'view':
                url =  '/pages/mine/view'
                break;
              case 'like':
                url =  '/pages/mine/like'
                break;
              case 'mine':
                url =  '/pages/mine'
                break;
              case 'hot':
                url =  '/pages/hot'
                break;
              case 'new':
                url =  '/pages/new'
                break;
              default:
                url =  '/pages/hot'
            }
            return url + '?page_num='  + this.currentPage[type]
        },
        resetMineItems() {
            this.isLoaded['mine'] = false
        },
        fetchItems(type = 'hot', page = 1) {
            return new Promise((resolve) => {
                if (this.isLoaded[type]) {
                    resolve(this[type + 'Items']);
                    return
                }

                this.loading = true
                const url = this.getLoadUrl(type)
                api.get(url).then(response => {
                    const data = response.data.data;

                    if (page == 1) {
                        this[type + 'Items'] = data
                    } else {
                        this[type + 'Items'] = this[type + 'Items'].concat(data)
                    }

                    if (data.length < process.env.VUE_APP_PAGE_SIZE)
                        this.finished[type] = true

                    this.loading = false
                    this.loadingMore = false

                    this.isLoaded[type] = true 
                    resolve(this[type + 'Items']);
                }).catch(error => {
                    this.loading = false
                    this.error = error
                    resolve([]);
                })

            });
        },
        search(keyword, page=1) {
            return new Promise((resolve) => {
                var params = {
                    page_num: page,
                    keyword: keyword
                }
                this.loading = true
                api.post('/search', params).then(response => {

                    const result = response.data;
                    if (result.status =='success') {
                        if (page == 1) {
                            this.searchResult = result.data
                        } else {
                            this.searchResult = this.searchResult.concat(result.data)
                        }

                        if (result.data.length < process.env.VUE_APP_PAGE_SIZE)
                            this.finished['search'] = true
                    }

                    this.loading = false
                    this.loadingMore = false
                    resolve(this.searchResult);
                })
            })
        },
        loadMoreItems(type) {
            this.isLoaded[type] = false;
            this.currentPage[type]++;
            this.loadingMore = true
            this.fetchItems(type, this.currentPage[type]);
        },
        loadMoreSearchResult(keyword) {
            this.currentPage['search']++;
            this.search(keyword, this.currentPage['search']);
        },
        // 从缓存数据中删除画板
        removePage(pageId) {
            const pageIndex = this.mineItems.findIndex(page => page.page_uuid === pageId);
            if (pageIndex !== -1) {
                this.mineItems.splice(pageIndex, 1);
            } else {
                // console.log('gututu 找不到 ')
            }
        },
        saveScrollPosition(type, position) {
            this.scrollPosition[type] = position;
        },
        restoreScrollPosition(type) {
            return this.scrollPosition[type];
        },
        setMinePageType(type) {
            this.minePageType = type;
        },
        setHomePageType(type) {
            this.homePageType = type;
        },
        clearPageData() {
            this.mineItems = [];
            this.likeItems = [];
            this.collectItems = [];
            this.viewItems = [];
            this.newItems = [];

            this.searchResult = [];
            this.currentPage['mine'] = 1
            this.currentPage['like'] = 1
            this.currentPage['collect'] = 1
            this.currentPage['view'] = 1
            this.currentPage['new'] = 1
            
            this.isLoaded['mine'] = false
            this.isLoaded['like'] = false
            this.isLoaded['view'] = false
            this.isLoaded['collect'] = false
            this.isLoaded['new'] = false
        }

  }
});