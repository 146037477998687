// HotPageStore.js
import api from '@/common/api'; 
import { defineStore } from 'pinia';

export const useCardStore = defineStore('page', {
    state: () => ({
      pages: [],
      isLoaded: false,
      loading: false,
      error: null,
      scrollPosition: 0
    }),
    
    actions: {
        // 添加或更新卡片信息
        addOrUpdatePage(pageId, cards) {
            this.pages[pageId] = cards;
        },
        // 获取卡片信息
        getPage(pageId) {
            if (this.pages[pageId]) {
                return this.pages[pageId];
            } else {
                return [];
            }
        },
        // 清空画板的卡片数组缓存
        removePage(pageId) {
            this.pages[pageId] = undefined;
        },
        // 删除卡片信息
        removeCard(pageId, cardId) {
            const cards = this.pages[pageId]
            if (cards) {
                const cardIndex = cards.findIndex(card => card.card_uuid === cardId);
                if (cardIndex !== -1) {
                    this.pages[pageId].splice(cardIndex, 1);
                }
            }
        },
        fetchCards(pageId, reset=false) {
            return new Promise((resolve) => {
                if (this.pages[pageId] && !reset) 
                {
                    resolve(this.pages[pageId])
                    return
                }

                this.loading = true
                
                api.get('/pages/' + pageId + '/cards')
                .then(response => {
                  this.loading = false
                  const result = response.data
                  if (result.status == 'success') {
                    this.addOrUpdatePage(pageId, result.data)
                    resolve(result.data)
                  }
                }).catch(error => {
                    this.loading = false
                    this.error = error
                    resolve([]);
                })
            });
        },
        isEmpty(pageId) {
            const cards = this.pages[pageId];
            if (cards == undefined) {
                return false;
            } else {
                return this.pages[pageId].length == 0 && !this.loading
            }
        },
        copyCard(pageId, cardId) {
            const cards = this.pages[pageId];
            if (cards) {
                const card = cards.find(card => card.card_id === cardId);
                this.pages[pageId].push(card);
            }
        },
        addCard(pageId, card) {
            const cards = this.pages[pageId];
            if (cards) {
                this.pages[pageId].push(card);
            }
        },

        saveScrollPosition(position) {
            this.scrollPosition = position;
        },
        restoreScrollPosition() {
            return this.scrollPosition;
        }
  }
});